import "../stylesheets/index.css";
import "@hotwired/turbo-rails";
import {
  CharacterCounterElement,
  ContentValidatorElement,
  OpenDialogElement,
  PasswordRevealElement,
  PasswordValidatorElement,
  TextareaAutoresizeElement,
} from "../elements";

CharacterCounterElement.register();
ContentValidatorElement.register();
OpenDialogElement.register();
PasswordRevealElement.register();
PasswordValidatorElement.register();
TextareaAutoresizeElement.register();
