import ApplicationElement from "./application_element";

export default class TextareaAutoresizeElement extends ApplicationElement {
  static tagName = "textarea-autoresize";
  static refs = ["textarea"];

  afterConnected() {
    if (this.dataset.textareaAutoresize) {
      this.refs.textarea.style.height = "auto";
      this.refs.textarea.style.height = this.refs.textarea.scrollHeight + "px";
    }
  }
}
