import ApplicationElement from "./application_element";

export default class OpenDialogElement extends ApplicationElement {
  static tagName = "open-dialog";
  static refs = ["dialog"];

  afterConnected() {
    // If the browser still has a cached version of our modal we cannot call `.showModal` since the modal is already open
    // To ensure a consistent state, we close and then open the element
    if (this.refs.dialog.open) this.refs.dialog.close();
    this.refs.dialog.showModal();
  }
}
