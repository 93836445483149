/**
 * Calculate the scores for a signal's content
 *
 * @param {string} content
 * @param {AbortSignal?} signal
 */
export async function AIFeedback(content, signal) {
  try {
    const response = await fetchWithCSRF("/raw_signal_candidates/ai_feedback", {
      method: "POST",
      body: JSON.stringify({
        raw_signal_candidate: {
          content,
        },
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "text/html",
      },
      signal,
    });
    if (response.ok) {
      return await response.text();
    } else {
      // We can't notify the user of any issues, so we log this in the console for now
      // eslint-disable-next-line no-console
      console.error(response.status);
    }
  } catch (error) {
    // We catch errors so we can swallow any instances of `AbortError` and re-throw any others
    // Since rack-mini-profiler monkey-patches fetch, we can still see these errors in development
    // See: https://github.com/MiniProfiler/rack-mini-profiler/issues/489
    if (error instanceof Error && error.name !== "AbortError") throw error;
  }
}

/**
 * Perform a request with rails' CSRF token
 *
 * @param {string | URL} url
 * @param {RequestInit} options
 */
export async function fetchWithCSRF(url, options) {
  const token = document
    .querySelector("meta[name=csrf-token]")
    ?.getAttribute("content");

  options.headers ||= {};
  options.headers["X-CSRF-Token"] ||= token;

  return await fetch(url, options);
}
